import './App.css';
import styled from "styled-components";
import React from 'react';
import { observer } from "mobx-react";
import { extendObservable, computed, makeObservable } from "mobx";
import elasticlunr from "elasticlunr";
import APIService from "./services/api"

class App extends React.Component {
  constructor(props) {
    super(props);

    extendObservable(this, {
      letters: [],
      recipes: [],
      pinnedRecipes: [],
      categories: [],
      searchQuery: "",
      selectedCategory: null,
      bottomOffset: 0,
      state: {
        isLoading: true,
        isError: false,
      }
    })

    makeObservable(this, {
      filteredRecipes: computed,
    });

    this.searchIndex = elasticlunr(function () {
      this.addField('title');
      this.addField('subtitle');
      this.setRef('id');
    });
  }

  get filteredRecipes() {
    const query = this.searchQuery;
    let data = this.recipes;

    if (this.selectedCategory) {
      data = data.filter(r => r.category === this.selectedCategory)
    }

    if (query.trim().length === 0) {
      return data;
    } else {
      const searchResult = this.searchIndex.search(query, {
        fields: {
          title: { boost: 2 },
          subtitle: { boost: 1 }
        },
        expand: true
      }).map(o => parseInt(o.ref, 10));

      return data.filter(recipe => searchResult.includes(recipe.id));
    }
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      const recipes = await APIService.recipes().get();
      const collator = new Intl.Collator('sl');

      this.recipes = recipes.sort((a, b) => collator.compare(a.title, b.title));

      this.recipes.forEach((recipe) => this.searchIndex.addDoc(recipe));

      const categories = await await APIService.categories().get();
      this.categories = categories;
      this.selectedCategory = this.categories.length > 0 ? this.categories[0].id : null;

      this.state.isLoading = false;
    } catch (e) {
      this.state.isError = e;
      console.log(e);
    }
  }

  pinRecipe = (recipe) => {
    const exists = this.pinnedRecipes.find(r => r.id === recipe.id);

    if (exists) {
      return;
    }

    if (this.pinnedRecipes.length < 5) {
      const max = this.pinnedRecipes
        .map(recipe => recipe.numericalIndex || 0)
        .reduce((prev, curr) => curr > prev ? curr : prev, 0);
      const index = max + 1;

      recipe.numericalIndex = index;
      this.pinnedRecipes.push(recipe);
    }

    this.updateOffset();
  }

  updateOffset = () => {
    setTimeout(() => {
      const pinnedContainer = document.body.querySelector(".pinned");
      if (pinnedContainer) {
        this.bottomOffset = pinnedContainer.clientHeight + 100;
      }
    }, 200)
  }

  unpinRecipe = (recipe) => {
    recipe.numericalIndex = null;
    this.pinnedRecipes = this.pinnedRecipes.filter(r => r.id !== recipe.id);
    this.updateOffset();
  }

  onLetterClick = (letter) => {
    const firstRecipe = this.filteredRecipes.find(recipe => recipe.title[0].localeCompare(letter.toUpperCase()) === 0);

    if (!firstRecipe) {
      return;
    }

    const element = document.querySelector(`[data-recipe-id='${firstRecipe.id}']`);
    const container = document.querySelector(".recipes");

    if (element) {
      scrollTo(container, element.offsetTop - 73 - 72 - 50, 300);
    }
  }

  handleSearchQueryChange = (event) => {
    this.searchQuery = event.target.value;
  }

  toggleCategorySelection = (id) => {
    if (this.selectedCategory && this.selectedCategory === id) {
      this.selectedCategory = null
    } else {
      this.selectedCategory = id
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Main>
        <StateLoading>Loading...</StateLoading>
      </Main>
    }

    if (this.state.isError && this.recipes.length === 0) {
      return <Main>
        <StateLoading>Error, while loading content!</StateLoading>
      </Main>
    }

    if (this.recipes.length === 0) {
      return <Main>
        <StateLoading>No content to display!</StateLoading>
      </Main>
    }

    return <Main>
      <Content>
        <Searchbar>
          <input placeholder="Iskanje receptov po nazivu ali opisu..." value={this.searchQuery} onChange={this.handleSearchQueryChange} />
        </Searchbar>
        <Categories>
          {this.categories.map(category => {
            return <Category
              key={category.id}
              onClick={() => this.toggleCategorySelection(category.id)}
              selected={this.selectedCategory === category.id}>
              {category.title}
            </Category>
          })}
        </Categories>
        <Recipes className="recipes" offset={this.bottomOffset}>
          {
            this.filteredRecipes.map((recipe, index) => {
              let alphabet = null;

              if (index > 0 && this.filteredRecipes[index - 1].title[0].toUpperCase() !== recipe.title[0].toUpperCase()) {
                alphabet = recipe.title[0].toUpperCase();
              } else if (index === 0) {
                alphabet = recipe.title[0].toUpperCase();
              }

              return <div key={recipe.id}>
                {alphabet && <Alphabet>{alphabet}</Alphabet>}
                <Recipe data-recipe-id={recipe.id} onClick={this.pinRecipe.bind(null, recipe)} key={recipe.id}>
                  <RecipeContent>
                    <Title>{recipe.title}</Title>
                    <Description>{recipe.subtitle}</Description>
                  </RecipeContent>
                </Recipe>
              </div>;
            })
          }
        </Recipes>
        <PinnedItems className="pinned" visible={this.pinnedRecipes.length > 0}>
          {
            this.pinnedRecipes.map(recipe => <Recipe onClick={this.unpinRecipe.bind(null, recipe)} key={recipe.id}>
              <Index>{recipe.numericalIndex}</Index>
              <RecipeContent>
                <Title>{recipe.title}</Title>
                <Description>{recipe.subtitle}</Description>
              </RecipeContent>
            </Recipe>)
          }
        </PinnedItems>
      </Content>
      <Aside>
        <ul>
          {getFirstLetters(this.filteredRecipes, 'title').map(letter => <li onClick={this.onLetterClick.bind(null, letter)} key={letter}>{letter}</li>)}
        </ul>
      </Aside>
    </Main>
  }
}

function getFirstLetters(recipes, property) {
  const titles = new Set(recipes.map(item => item[property][0]));
  const letters = [...titles].sort((a, b) => a.localeCompare(b));

  return letters;
}

function scrollTo(container, elementY, duration) {
  var startingY = container.scrollTop;
  var diff = elementY - startingY;
  var start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    container.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

export default observer(App);

const StateLoading = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 5rem;
`;

const Main = styled.div`
  display: flex;
  height: 100vh;
  background: white;
`;

const Aside = styled.div`
  height: 100vh;
    
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
    li {
      border: 1px solid black;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Recipes = styled.div.attrs(props => ({
  style: {
    paddingBottom: props.offset
  }
}))`
  flex-grow: 1;
  overflow-y: scroll;
`;

const Recipe = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #c3c3c3;
  cursor: pointer;
  display: flex;
  user-select: none;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-size: 16px;
`;

const Index = styled.div`
  margin-right: 20px;
  font-size: 30px;
  width: 30px;
  opacity: 0.5;
  flex-shrink: 0;
`;

const RecipeContent = styled.div`
  flex-grow: 1;
`;

const PinnedItems = styled.div`
  margin: 0 2rem;
  background: rgb(25, 29, 50);
  color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 -5px 20px rgba(0,0,0,0.1); 
  padding: 1rem;
  transition: .6 all;
  position: fixed;
  bottom: 0;
  right: 5rem;
  left: 0rem;
  pointer-events: ${p => !p.visible && 'none'};
  opacity: ${p => !p.visible && 0};
`;

const Alphabet = styled.div`
  margin-left: 1rem;
  font-size: 42px;
  margin-top: 2rem;
  font-weight: bold;
  font-family: 'Dancing Script', cursive;
`;

const Searchbar = styled.div`
  padding: 1rem;

  input {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding: 10px 1rem;
    border-radius: 20px;
    border: 1px solid #cecece;
  }
`;

const Categories = styled.div`
  display: flex;
  padding: 1rem;
  margin-top: -1rem;
`;

const Category = styled.div`
  cursor: pointer;
  background: ${p => p.selected && 'rgb(25, 29, 50)'};
  color: ${p => p.selected ? 'white' : 'black'};
  border: 1px solid #ebebeb;
  border-radius: 20px;
  margin-right: 1rem;
  padding: 10px 2rem;
  user-select: none;
`;