import axios from "axios";

class APIService {

    client;

    constructor() {
        this.client = axios.create({
            headers: {},
            baseURL: "https://menu-api.captains-cabin.si/recipes",
        });
    }

    categories = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/categories`, {
                params: query
            })).data;
        }
    });

    recipes = () => ({
        get: async (query = {}) => {
            return (await this.client.get(`/`, {
                params: query
            })).data;
        }
    });

}

export default new APIService();
