import React from 'react';
import styled from "styled-components";
import logo from './logo.svg'

import { observer } from "mobx-react";
import { extendObservable } from "mobx";
import { Button, Input, Stack } from '@chakra-ui/react'

const CORRECT_PASSWORD = btoa('050')

class PasswordProtected extends React.Component {
    constructor(props) {
        super(props);

        extendObservable(this, {
            isLoggedIn: false,
        })

        const existingPassword = localStorage.getItem('profile');

        if (existingPassword && existingPassword === CORRECT_PASSWORD) {
            this.isLoggedIn = true;
        } else if (existingPassword) {
            localStorage.removeItem('profile')
        }
    }

    login = () => {
        if (btoa(this.inputNode.value) === CORRECT_PASSWORD) {
            this.isLoggedIn = true
            this.savePasswordToStorage(this.inputNode.value);
        } else {
            alert('Password is not correct.')
        }

        this.inputNode.value = ''
    }

    savePasswordToStorage = (password) => {
        localStorage.setItem('profile', btoa(password))
    }

    render() {
        if (this.isLoggedIn) {
            return this.props.children;
        }

        return <Container>
            <Stack>
                <img src={logo} alt="" />
                <Input pattern="[0-9]*" inputmode="numeric" ref={node => (this.inputNode = node)} type="password" placeholder='Enter password...' />
                <Button onClick={this.login}>Login</Button>
            </Stack>
        </Container>
    }
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
`

export default observer(PasswordProtected);